//
// cerebrisans fonts
//

@font-face {
  font-family: 'Cerebri';
  src: url('../../../fonts/cerebrisans-regular.eot');
  src: local('Cerebri Regular'), local('cerebrisans-regular'),
    url('../../../fonts/cerebrisans-regular.eot?#iefix') format('embedded-opentype'),
    url('../../../fonts/cerebrisans-regular.woff') format('woff'),
    url('../../../fonts/cerebrisans-regular.ttf') format('truetype'),
    url('../../../fonts/cerebrisans-regular.svg#Roboto') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Cerebri';
  src: url('../../../fonts/cerebrisans-semibold.eot');
  src: local('Cerebri SemiBold'), local('cerebrisans-semibold'),
    url('../../../fonts/cerebrisans-semibold.eot?#iefix') format('embedded-opentype'),
    url('../../../fonts/cerebrisans-semibold.woff') format('woff'),
    url('../../../fonts/cerebrisans-semibold.ttf') format('truetype'),
    url('../../../fonts/cerebrisans-semibold.svg#Roboto') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Cerebri';
  src: url('../../../fonts/cerebrisans-bold.eot');
  src: local('Cerebri Bold'), local('cerebrisans-bold'),
    url('../../../fonts/cerebrisans-bold.eot?#iefix') format('embedded-opentype'),
    url('../../../fonts/cerebrisans-bold.woff') format('woff'),
    url('../../../fonts/cerebrisans-bold.ttf') format('truetype'),
    url('../../../fonts/cerebrisans-bold.svg#Roboto') format('svg');
  font-weight: 700;
  font-style: normal;
}

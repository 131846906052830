//
// horizontal-nav.scss
//

.topnav-navbar {
  padding: 0;
  margin: 0;
  min-height: 75px;

  .topnav-logo {
    line-height: 75px;
    float: left;
    padding-right: 20px;
    min-width: 160px;

    .topnav-logo-sm {
      display: none;
    }
  }
}

.topnav {
  background: $bg-topnav;
  box-shadow: 0 1px 1px rgba($color: #000000, $alpha: 0.1);

  .topnav-menu {
    margin: 0;
    padding: 0;
  }
}

.topbar-nav {
  .badge {
    // display: none;
  }

  .metismenu {
    display: flex;
    flex-direction: column;

    .has-arrow {
      &:after {
        width: 0.4em;
        height: 0.4em;
        right: 1rem;
        transform: rotate(45deg) translate(0, -50%);
      }
    }
  }

  .side-nav-link {
    font-size: 0.9375rem;
    position: relative;
    padding: 0.832rem 1.3rem;

    &.disabled,
    span.badge.badge-light.badge-pill {
      color: $gray-600 !important;
    }

    &.active {
      color: $gray-900;
      border-bottom: 4px solid $primary;
      padding-bottom: calc(0.832rem - 4px);
    }

    i {
      margin: 0 0.375rem 0 0;
      font-size: 0.875rem;
      vertical-align: baseline;
    }

    &.has-arrow {
      padding-right: 2rem;
    }
  }
}

@include media-breakpoint-down(lg) {
  body[data-layout='topnav'] {
    .container-fluid {
      max-width: 96% !important;
    }
  }
}

@include media-breakpoint-up(lg) {
  body[data-layout='topnav'] {
    .container-fluid {
      max-width: 90%;
    }

    &.boxed-layout {
      .container-fluid {
        max-width: 97%;
      }
    }
  }

  .topbar-nav {
    .metismenu {
      flex-direction: row;

      > li {
        flex-direction: column;

        > ul {
          position: absolute;
          z-index: 1001;
        }
      }

      .side-nav-item {
        &:first-of-type {
          .side-nav-link {
            padding-left: 0;
          }
        }

        .side-nav-second-level {
          color: $dropdown-color;
          min-width: $dropdown-min-width;
          background-color: $dropdown-bg;
          background-clip: padding-box;
          border: $dropdown-border-width solid $dropdown-border-color;
          border-radius: 0 0 $dropdown-border-radius $dropdown-border-radius;
          box-shadow: $shadow;

          .side-nav-item {
            &:first-of-type {
              padding-top: $dropdown-padding-y;
            }

            &:last-of-type {
              padding-bottom: $dropdown-padding-y;
            }
          }

          .side-sub-nav-link {
            display: block;
            width: 100%;
            padding: $dropdown-item-padding-y $dropdown-item-padding-x !important;
            clear: both;
            font-weight: $font-weight-normal;
            color: $dropdown-link-color !important;
            font-size: 0.875rem;
            white-space: nowrap;
            background-color: transparent;
            border: 0;

            &.active {
              font-weight: bold;
            }

            @include hover-focus {
              color: $dropdown-link-hover-color;
              text-decoration: none;
              @include gradient-bg($dropdown-link-hover-bg);
            }
          }

          .has-arrow {
            &:after {
              margin-top: -2px;
            }
          }
        }

        .side-nav-third-level {
          .side-sub-nav-link {
            padding-left: 2.2rem !important;
          }
        }
      }
    }
  }

  .navbar-toggle {
    display: none;
  }
}

@include media-breakpoint-down(md) {
  .topnav {
    .navbar-nav {
      .nav-link {
        padding: 0.75rem 1.3rem;
      }
    }

    .dropdown {
      .dropdown-menu {
        background-color: transparent;
        border: none;
        box-shadow: none;
        padding-left: 15px;
      }

      .dropdown-item {
        position: relative;
        color: rgba($white, 0.5);
        background-color: transparent;

        &.active,
        &:active {
          color: $white;
          font-weight: bold;
        }
      }
    }

    .arrow-down {
      &::after {
        right: 15px;
        position: absolute;
      }
    }
  }
}

@media (max-width: 1290px) {
  .topnav-navbar {
    .topnav-logo-lg {
      display: none;
    }

    .topnav-logo {
      min-width: 50px;
    }

    .topnav-logo-sm {
      display: block !important;
    }

    .topnav-version {
      display: none;
    }
  }
}

@include media-breakpoint-down(md) {
  body[data-layout='topnav'] {
    .navbar-custom {
      .button-menu-mobile {
        &.disable-btn {
          display: inline-block;
        }
      }
    }
  }
}

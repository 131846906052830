$white: #fff !default;
$gray-100: lighten($dark, 74%);
$gray-200: lighten($dark, 70%);
$gray-300: lighten($dark, 64%) !default;
$gray-400: lighten($dark, 60%) !default;
$gray-500: lighten($dark, 54%) !default;
$gray-600: lighten($dark, 50%);
$gray-700: lighten($dark, 44%);
$gray-800: lighten($dark, 40%) !default;
$gray-900: lighten($dark, 34%);
$black: #282a31 !default;
$darkGray: #32303a !default;

$grays: ();
$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
  ),
  $grays
);

$default_color: #da291c;
$blue: #2c8ef8;
$indigo: #727cf5;
$purple: #a521d8;
$pink: #ff679b;
$red: #fa5c7c;
$orange: #fd7e14;
$yellow: #ffbc00;
$green: #12de96;
$greenDark: darken($green, 6%);
$teal: #02a8b5;
$cyan: #39afd1;

$colors: ();
$colors: map-merge(
  (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $greenDark,
    'teal': $teal,
    'cyan': $cyan,
    'white': $white,
    'gray': $gray-600,
    'gray-dark': $gray-800,
  ),
  $colors
);

$primary: $default_color;
$secondary: $gray-900;
$success: $green;
$info: $greenDark;
$warning: $yellow;
$danger: $red;
$light: $gray-400;
$dark: $darkGray;
$bg-color: #32303a;

@import '../_default/variables';
